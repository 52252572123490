.app {
    height: 100%;
    background-color: green;
}

.sobre {
    text-align: center;
    margin-top: 1rem;
    height: 100%;
}

.categorias {
    display: flex;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 2rem;
    flex-direction: column;
    text-align: center;
    gap: 3rem;
    
    a{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: darkgreen;
        color: white;
        font-weight: bold;
        height: 2rem;
        border-radius: 8px;
        text-decoration: none;
        border: 1px solid black;
    }
}

