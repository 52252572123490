.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Cor de fundo do container */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.content {
    position: static;
    text-align: center;
    width: 100%;
    height: 100%;
}

.title {
    font-size: 3rem; /* Tamanho da fonte do título */
    color: #333; /* Cor do título */
    margin-bottom: 10px;
}

.subtitle {
    font-size: 1.5rem; /* Tamanho da fonte do subtítulo */
    color: #666; /* Cor do subtítulo */
    margin-bottom: 20px;
}

.description {
    font-size: 1rem; /* Tamanho da fonte da descrição */
    line-height: 1.5; /* Altura da linha da descrição */
    color: #444; /* Cor do texto da descrição */
}
