.appHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;

}

.linksCategorias {
  display: flex;
  justify-content: center;
  gap: 60px;
  background-color: #3d3d3d;
  align-items: center;
  width: 100%;
  height: 10vmin;
}

.links {
  color: #FFFFFF;
  font-weight: 700;
  background-color: transparent;
  font-size: 4vmin;
  text-decoration: none;
  cursor: pointer;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* fundo semi-transparente para destacar o modal */
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

/* Estilos para os botões */
.button-row {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

.visitor-row {
  margin-top: 1rem;
  text-align: center;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cadastrar-button {
  background-color: #007bff;
  width: 10rem;
  color: white;
}

.logoutButton {
  background-color: #FFFFFF;
  color: #000000;
}

.visitor-button {
  background-color: transparent;
  /* cinza */
  color: black;
}

/* Hover styles */
button:hover {
  opacity: 0.8;
}

.containerLogout {
  display: flex;
  padding-left: 10px;
  color: #FFFFFF !important;
  align-items: center;
}
