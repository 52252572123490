// CodigoPenalPage/styles.scss

// Estilos globais e variáveis podem ser adicionados aqui
$primary-color: #000000;
$secondary-color: #414040;
$font-family: 'Arial, sans-serif';

// Estilos específicos para o componente CodigoPenalPage
.App {
    font-family: $font-family;
    text-align: center;
}

.contato {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.sobre {
    color: $primary-color;
}

.dados {
    display: flex;
    flex-direction: row;
    gap: 8rem;
    justify-content: space-around;
}

.input_container {
    margin-bottom: 10px;
    width: 100%;
    label {
        color: #000;
        display: block;
        margin-bottom: 1px;
    }

    input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .crimes {
        flex: 1;
        padding: 10px;
        margin: 10px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        h3 {
            color: $secondary-color;
            margin-bottom: .5rem;
            font-size: 1rem;
        }
    }

    .calculadorapenal {
        flex: 1;
        padding: 10px;
        margin: 10px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin-bottom: 10px;
        }
    }
}

.textarea {
    color: #000;
}