.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1e1e1e; /* Cor de fundo escura */
    color: #fff; /* Cor do texto */
    position: relative; /* Adiciona posição relativa para a sobreposição */
    overflow: hidden; /* Oculta os flocos de neve que estão fora do contêiner */
}

/* Define o estilo dos flocos de neve */
.snowflake {
    position: absolute;
    width: 2px; /* Largura do floco de neve */
    height: 2px; /* Altura do floco de neve */
    background-color: #fff; /* Cor do floco de neve */
    opacity: 0.7; /* Opacidade do floco de neve */
    animation: snow-fall linear infinite; /* Animação de queda de neve */
}

/* Define a animação de queda de neve */
@keyframes snow-fall {
    0% {
        transform: translateY(-110vh) rotate(0deg); /* Início da animação */
    }
    100% {
        transform: translateY(110vh) rotate(360deg); /* Fim da animação */
    }
}

h1{
    color: white;
}
label{
    color: white;
}

.loginTitle {
    font-size: 32px;
    margin-bottom: 20px;
}

.loginForm {
    width: 300px;
}

.loginLabel {
    margin-bottom: 5px;
}

.loginInput {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #333; /* Cor de fundo do input */
    color: #fff; /* Cor do texto do input */
}

.loginInput::placeholder {
    color: #bbb; /* Cor do placeholder do input */
}

.loginButton {
    width: 100%;
    padding: 10px;
    background-color: #007bff; /* Cor de fundo do botão */
    color: #fff; /* Cor do texto do botão */
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.loginButton:hover {
    background-color: #0056b3; /* Cor de fundo do botão ao passar o mouse */
}

.loginError {
    color: red;
    margin-top: 10px;
}
