.checkbox {
    display: flex;
    align-items: center; /* Centraliza verticalmente */
}

.checkbox input[type="checkbox"] {
    padding: 0px !important;
    width: 25px;
}

.checkbox label {
    font-size: 1rem; /* Tamanho da fonte do label */
    color: #333; /* Cor do texto do label */
    cursor: pointer; /* Mostra o cursor como apontador ao passar por cima */
}

.checkbox input[type="checkbox"]:checked + label {
    font-weight: bold; /* Deixa o texto em negrito quando o checkbox está marcado */
    color: #007bff; /* Muda a cor do texto quando o checkbox está marcado */
}
