* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}


.primeira-row {
  display: flex;
  gap: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.segunda-row {
  margin-left: 5rem;
  margin-right: 5rem;
}

.terceira-row {
  margin-left: 5rem;
  margin-right: 5rem;
}

.quarta-row {
  margin-left: 5rem;
  margin-right: 5rem;
}

.quinta-row {
  margin-left: 5rem;
  margin-right: 5rem;
}

.sexta-row {
  gap: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.sobre {
  text-align: center;
  margin-top: 1rem;
}

label {
  font-size: 0.8rem;
  font-weight: bold;
}

.categorias {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  -moz-appearance: textfield;
}

input[type="text"],
input[type="date"],
input[type="email"],
input[type="password"],
input[type="number"] {
  margin: 0;
  margin-top: 0.5rem;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="obs"] {
  padding: 10px;
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  height: 10rem;
}

textarea[type="descricao"] {
  padding: 10px;
  margin-top: 0.5rem;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: vertical; /* Permite redimensionamento vertical */
  text-align: left; /* Alinhar o texto à esquerda */
}

legend {
  background-color: transparent;
  color: #000000;
  font-weight: bold;
  padding: 3px 6px;
}

input {
  margin: 0.4rem;
  width: 100%;
}


.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.buttons-container .btn {
  width: 150px;
  margin: 0 0.5rem;
}

.select-container {
  width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
select {
  width: 100%;
  margin-top: 0.5rem;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.input-container {
  width: 100%;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Adiciona um fundo escuro semi-transparente */
}

.modal {
  position: relative;
  width: 30%;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%; /* Define a largura máxima do modal */
  background-color: #fff; /* Cor de fundo do modal */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Adiciona uma sombra ao redor do modal */
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
}

.modal-buttons {
  text-align: center;
  margin-top: 20px;
}

.modal-buttons button {
  margin-right: 10px;
}
